import "./Main.scss";
import PackageItem from "./components/PackageItem";
import PageFooter from "../general/components/PageFooter";
import {useEffect} from "react";

const content = {
  title: "고등리그, 23년부터\n 분석 서비스 중단 안내",
  intro: [
    `안녕하세요 고등리그 팀 관계자 여러분.`,
    `비프로는 지난 3년간 정부와 대한축구협회의 지원을 통해 ‘전국 고등리그 경기 분석 시스템’서비스를 모든 고등리그 참가 팀들에게 무료로 제공해왔습니다.`,
    `<b>올해를 끝으로 해당 사업이 종료</b>되면서 내년부터는 전국 고등리그 경기 촬영 및 분석 서비스를 제공드릴 수 없게 되었습니다.`,
    `<b>2023년 1월 1일</b>부터는 비프로 <strong>유료 가입 팀에게만 선수의 영상 및 데이터 서비스를 제공</strong>하며, 유료 가입을 진행하지 않는 팀의 경우 기존 영상 및 데이터 조회가 불가능합니다.`,
    `유료 계약을 희망하는 팀에 비프로 서비스를 안내하고 도움을 드리고자 합니다. 아래 내용을 참고하여 주시기 바랍니다.`,
  ],
  apply: [
    `분석 서비스 이용을 위해서는 <strong>각 팀별 계약이 필요</strong>합니다.`,
    `팀 단위 계약으로 진행됨에 따라 <b>각 팀의 지도자, 선수, 그리고 학부모분들 간의 논의 후</b> <strong>지도자를 통해 문의</strong>해주시기 바랍니다.`,
  ],
  outro: [
    `저희 비프로는 지속적인 서비스 제공을 위해 노력하고 있습니다. 문의사항이 있으실 경우 언제든 위 "패키지 문의"를 통해주시기 바랍니다. 감사합니다.`
  ]
}

const generateTextBlocks = (textBlocks) => {
  return textBlocks.map( (text, i) => {
    return <p key={i} dangerouslySetInnerHTML={{__html: text}}/>
  })
}

const Main = ({teams}) => {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="page main">
      <div className="pre-header">
        <img src={require("../img/bepro-ci-black.png")} alt=""/>
      </div>
      <div className="header">
        <img src={require("../img/bepro-ci-white.png")} alt=""/>
        <h1>{content.title}</h1>
        <div className="receiver">고등리그 지도자 · 선수 · 학부모분들께</div>
      </div>
      <div className="body">
        <img className="bg-pt lt" src={require("../img/bg-pt-lt.png")} alt=""/>
        <img className="bg-pt rb"  src={require("../img/bg-pt-rb.png")} alt=""/>

        <div className="boundary">
          <div className="content intro">
            {generateTextBlocks(content.intro)}
          </div>
          <div className="divider"/>
          <div className="content action-needed">
            <h2 className="sub-title">서비스 안내</h2>
            {generateTextBlocks(content.apply)}
            <div className="container-packages">
              <PackageItem/>
              {/*<PackageItem packageType={"basic"}/>*/}
            </div>
          </div>
          <div className="divider"/>
          <div className="content outro">
            {generateTextBlocks(content.outro)}
          </div>
        </div>
      </div>
      <PageFooter/>
    </div>
  )
}

export default Main;
