import {useLocation, useNavigate} from "react-router-dom";
import "./Applied.scss";
import {useEffect} from "react";

const Applied = () => {
  const {state} = useLocation();
  const {data} = state;
  const navigate = useNavigate();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="page apply completed">
      <img className="bg-pt lt" src={require("../img/bg-pt-lt.png")} alt=""/>
      <img className="bg-pt rb"  src={require("../img/bg-pt-rb.png")} alt=""/>

      <div className="header">
        <div className="intro">
          <img src={require("../img/bepro-ci-black.png")} alt=""/>
        </div>
        <h1>패키지 문의 완료</h1>
        <p className="completed-description">문의가 등록되었습니다!<br/>입력하신 연락처로 순차적으로 개별 안내 해드릴 예정이며<br/>다소 시간이 걸릴 수 있으니 양해 부탁드립니다.</p>

      </div>
      <div className="body">
        <div className="applied-info">
          {Object.keys(data).map( (itemKey, index) => {
            return (
              <div className="applied-data-item" key={index}>
                <p className="label">{data[itemKey].title}</p>
                <p className="value">{data[itemKey].value || "-"}</p>
              </div>
            )
          })}
        </div>
        <div className="btn-wrapper">
          <button className="btn-apply btn-red-outlined"
                  onClick={ () => {navigate("/")}}>안내 페이지 홈으로 이동</button>
        </div>
        <div className="btn-wrapper">
          <a className="btn-apply btn-red-outlined" href={"https://www.bepro11.com"}>비프로 홈으로 이동</a>
        </div>

      </div>
    </div>
  )
}

export default Applied;
