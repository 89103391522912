import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import "./Apply.scss";
import ModalSelectTeam from "./components/ModalSelectTeam";
import BufferSpinner from "../general/components/BufferSpinner";
import {isMobile} from "react-device-detect";
import axios from "axios";

const Apply = ({teams}) => {
  const navigate = useNavigate();
  const [currentFormItem, setCurrentFormItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [form, setForm] = useState({
      team: {title: "팀", type:"button", value: "", isRequired: true},
      name: {title: "이름", placeholder:"이름", type:"input", value: "", isRequired: true},
      role: {title: "직책", type:"button", value: "코치", isRequired: true},
      contact: {title: "연락처", placeholder:"회신 받을 연락처 (이메일 / 휴대폰)", type:"input", value: "", isRequired: true},
      inquiry: {title: "기타 문의", placeholder: "기타 문의사항을 작성해주세요", type:"textarea", value: "", isRequired: false}
    }
  )

  const checkIfSubmittable = () => {
    let submittable = true;
    let itemKeys = Object.keys(form);

    for(let i = 0; i < itemKeys.length; i++){
      let formItem = form[itemKeys[i]];
      if(formItem.isRequired && !formItem.value){
        submittable = false;
        break;
      }
    }
    return submittable;
  };

  const updateFormData = (itemKey, value) => {
    let newForm = {...form};
    newForm[itemKey].value = value;

    setForm(newForm);
    setCurrentFormItem("");
  }

  const renderDropdown = (itemKey) => {
    const menuItems = {
      "role": ["감독", "코치", "분석관"]
    }

    return (
      <div className="dropdown-wrapper"
           onClick={(e) => {e.stopPropagation()}}>
        {
          itemKey  === "team" ? (
            <ModalSelectTeam teams={teams}
                             display={currentFormItem === itemKey}
                             onItemSelect={(team) => {
                               updateFormData("team", team.name);
                               setSelectedTeam(team);
                             }}
                             onClose={() => {setCurrentFormItem("")}}/>
          ) : (
            <div className={`dropdown ${currentFormItem === itemKey ? 'show' : 'hide'}`}>
              <div className="dropdown-body">
                {menuItems[itemKey].map((menu,index) => {
                  return (
                    <button className="btn-item" key={index}
                            onMouseDown={() => {updateFormData(itemKey, menu)}}>
                      <p>{menu}</p>
                    </button>
                  )
                })}
              </div>
            </div>
          )
        }
      </div>
    )
  }

  const renderFormItem = (itemKey) => {
    if(form[itemKey].type === "button"){
      return (
        <div className={`item-wrapper ${itemKey}`}>
          <button className={`form-item allow-focus select ${itemKey}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(currentFormItem === itemKey)
                      setCurrentFormItem("");
                    else
                      setCurrentFormItem(itemKey);
                  }}>
            <span className="label placeholder">{form[itemKey].title} {form[itemKey].isRequired ? "❊" : ""}</span>
            <div className={`${form[itemKey].value ? 'value' : 'placeholder'}`}>
              {form[itemKey].value || '선택해주세요'}
            </div>
            <i className="bepro-arrow-a-down"/>
          </button>
          {renderDropdown(itemKey)}
        </div>
      )
    }else if(form[itemKey].type === "input"){
      return (
        <div className="item-wrapper">
          <input className={`form-item allow-focus ${itemKey}`} placeholder={`${form[itemKey].placeholder} ${form[itemKey].isRequired ? "❊" : ""}`}
                 onChange={(e) => updateFormData(itemKey, e.target.value)}/>
        </div>
      )
    }else{
      return (
        <textarea className={`form-item allow-focus ${itemKey}`}
                  placeholder={form[itemKey].placeholder || form[itemKey].title}
                  onChange={(e) => updateFormData(itemKey, e.target.value)}/>
      )
    }
  }

  const sendInquiry = () => {
    setLoading(true);

    setTimeout( () => {
      let device = isMobile ? "휴대폰" : "랩탑/데스크탑";
      try {
        let data = JSON.stringify({
          blocks: [
            {
              "type": "divider"
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*기기 :* ${device}\n\n*팀 :* ${form.team.value}\n\n*이름 :* ${form.name.value}\n\n*역할 :* ${form.role.value}\n\n*연락처 :* ${form.contact.value}\n\n*문의사항 :* ${form.inquiry.value}\n\n`
              },
              "accessory": {
                "type": "image",
                "image_url": selectedTeam.imgUrl,
                "alt_text": "computer thumbnail"
              }
            },
          ]
        })
        axios.post("https://hooks.slack.com/services/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", data);

      } catch (e) {
        //
      } finally {
        setLoading(false);
        navigate("/applied", {state: {data: form}});
      }
    }, 500)
  }

  return (
    <div className="page apply" onClick={() => {setCurrentFormItem("")}}>
      <img className="bg-pt lt" src={require("../img/bg-pt-lt.png")} alt=""/>
      <img className="bg-pt rb"  src={require("../img/bg-pt-rb.png")} alt=""/>
      {loading ? (<div className="buffer-backdrop"><BufferSpinner/></div>) : ""}
      <div className="header">
        <div className="intro">
          <img src={require("../img/bepro-ci-black.png")} alt=""/>
        </div>
        <h1>고등리그 팀 <strong>패키지 문의</strong></h1>
        <span className="required-guide">❊ 필수 입력사항</span>
      </div>
      <div className="body">

        {renderFormItem("team")}

        <div className="container-form-items">
          {renderFormItem("name")}
          {renderFormItem("role")}
        </div>
        {renderFormItem("contact")}
        {renderFormItem("inquiry")}

        <div className="btn-wrapper">
          <button className="btn-apply btn-red-solid"
                  disabled={!checkIfSubmittable()}
                  onClick={ () => {sendInquiry()}}>
            문의 등록
          </button>
        </div>
      </div>

    </div>
  )
}

export default Apply;
