import "./PackageItem.scss";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

const PackageItem = ({packageType}) => {

  const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "laptop";
  }


  const navigate = useNavigate();

  const packageInfos = [
    {key: "분석 대상 범위", value: "리그 + 전국대회", subInfo: ""},
    {key: "기본 경기 분석", value: "10 경기", subInfo: "20경기 계약시 분석 가격 12.5% 할인"},
    {key: "추가 경기 분석 가능", value: "￦200,000 / 경기", subInfo: ""},
    {key: "비프로 촬영 지원 여부 선택 가능(유료)", value: "", subInfo: ""},
  ]
  const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const price = 2500000;

  return (
    <div className="package-item">
      <div className="package-item-header">
        <div className="title">
          <i className="bepro-analyse"/>
          <h2>분석 패키지</h2>
        </div>
      </div>

      <div className="package-item-body">
        {packageInfos.map( (info, index) => {
          return (
            <div className="package-info-item" key={index}>
              <div className="main">
                <div className="dot"/>
                <span className="key">{info.key}</span>
                <span className="value">{info.value}</span>
              </div>
              <div className="sub">
                {info.subInfo}
              </div>
            </div>
          )
        })}
      </div>

      <div className="package-item-footer">
        <div className="price">
          <div className="price-item">
            <p className="vat">10% 부가세 별도</p>
          </div>
          <div className="price-item final">
            <p className="final-price">￦{numberWithCommas(price)}<span> / 년</span></p>
            <p className="monthly">(￦{numberWithCommas(Math.round(price/12))} / 월)</p>
          </div>
        </div>
        <div className="container-buttons">
          <a className="btn-apply btn-red-outlined" href={"https://bepro.notion.site/KFA-ed3a46d03af04e01bba16e75aaba7b0c"}>
            <span>서비스 자세히 알아보기</span>
            {isMobile ? <span className={"sub"}>짧게 탭해서 안 될 경우,<br/>길게 눌러주세요</span> : ""}
          </a>
          <button className="btn-apply btn-red-solid"
                  onClick={() => {navigate(`/apply`)}}>
            패키지 문의
          </button>
        </div>


      </div>

    </div>
  )
}

export default PackageItem;
