import './BufferSpinner.scss';

import _ from 'lodash';
import React from 'react';

const BufferSpinner = ({ spinnerSize="medium", spinnerColor = '#000' }) => {
  return (
    <div className={`buffer-spinner ${spinnerSize}`}>
      {_.times(12, i => {
        return <div key={i} className="buffer-stick" style={{ backgroundColor: spinnerColor }} />;
      })}
    </div>
  );
};

export default BufferSpinner;
