export const teamListString = `10238,BLUEFCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13875,DREAMFC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8873,FC KHT 일동 U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102434.png
10320,FC광명시민U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13865,FC세경 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9301,J.SUN U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102416.png
13428,JHLFC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-03-11/64773_1646967220.9741993.png
9255,SC DONGA U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759226.349784.png
4017,SOLFC U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530363695.png
9312,가창FC하태호 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
2990,갑천고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-21%2F1_1529584365.png
5804,강경상업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8877,강릉문성고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096360.png
2991,강릉중앙고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-21%2F1_1529584069.png
13429,강화스포츠클럽 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8881,거제고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096379.png
4021,경기광문고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-09%2F1_1528504100.png
6373,경기오산고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9280,경남FC김해U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13501,경남거창FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9256,경남공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13225,경남대성FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9285,경남창녕FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10286,경북자연과학고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
5832,경신고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096414.png
9297,경영FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8872,경주정보고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4042,경희고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530363332.png
6023,계룡U18태양FC,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6400,계명고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558933154.png
10285,고령FC,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4044,고양FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759073.183205.png
6399,고양고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932991.png
9273,고창북고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9246,골클럽 U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-14/65041_1605347116.png
9307,과천고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096633.png
4049,광운인공지능고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558933292.png
4028,광진FC U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530365723.png
13866,광탄UDT U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8879,구리고등학교 축구단,https://storage.googleapis.com/bepro-server-storage/img/media/2020-07-23/68882_1595484111.jpeg
8855,군산제일고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096696.png
4027,글로벌선진고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530365591.png
4029,김영권축구클럽U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530385973.png
9325,김제FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6125,남강고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932740.png
4033,노원SKD FC U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530390609.png
4031,뉴양동FC U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530386398.png
9298,능곡고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-02/64773_1659404290.2395096.png
8856,대구공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6415,대동세무고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606096757.png
9310,대륜고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097187.png
8866,대신FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097206.png
9317,대한FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2021-04-03/5232_1617436373.png
8878,도봉FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4032,동국대부속고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530388044.png
9261,동래고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2021-04-16/64773_1618568072.png
13502,동명FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9258,동부산FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10328,동부산유나이티드,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
5877,동북고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2021-06-20/5232_1624162881.png
9284,마산공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10321,마포스파르탄즈 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8869,목포공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097396.png
4050,배재고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932403.png
9281,범어고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4051,보인고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-07-16/1_1563246107.png
9253,부경고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097417.png
9257,부산FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759484.2736511.png
9260,부산강서FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9259,부산정보고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9338,부천중동FCU18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-04-19/64773_1650337993.1537554.jpeg
4053,부평고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530365876.png
4026,삼일공업고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530364567.png
6016,상문고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097474.png
9320,상지대관령고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9271,새만금FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759539.950944.jpeg
13432,서귀포FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-30/64773_1661824032.2475152.png
8870,서귀포고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097492.png
13431,서귀포윈인FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10260,서귀포축구센터U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13555,서산FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9314,서울강북FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9313,서울공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6127,서울숭실고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097522.png
6128,서울인창고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661764746.007763.png
8861,서울중동FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4036,서울중앙고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530390702.png
4039,서해고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530391016.png
10319,송산FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9305,수원고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759654.388115.png
4046,수원공업고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530390350.png
6396,순천고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559037645.png
8860,신라고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097542.png
4054,신평고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530390938.png
4043,안산FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2019-12-23/1_1577082690.png
9306,안산유나이티드 U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759812.582517.png
13872,안성시G스포츠클럽 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4052,양천FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932484.png
8868,여의도고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13868,여주시 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13869,여주흥천FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6398,영광FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932564.png
9324,영덕고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2022-06-17/64773_1655437150.1802998.png
6123,영등포공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097583.png
13427,영등포선유FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9322,영주FC,https://storage.googleapis.com/bepro-server-storage/img/media/2022-06-17/64773_1655437138.8366463.jpeg
5805,예산삽교FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9323,예일메디텍고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8876,오상고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097600.png
4022,용문고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530364048.png
13430,용산자동차FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10318,용인레이번스축구단U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8867,용인시축구센터U18덕영,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097258.png
7904,용인양지FCU18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097621.png
7902,용인태성FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097757.png
4040,용호고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
4030,운호고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530387662.png
4034,울산학성고FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559036047.png
9319,원주공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097934.png
5807,유성생명과학고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097228.png
10316,의왕FCU18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759732.3207233.png
9302,의정부G스포츠클럽 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9303,의정부광동U18,https://storage.googleapis.com/bepro-server-storage/img/media/2021-03-29/81565_1616997839.png
9249,이동FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8032,이리고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6393,이천율면FCU18,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559028816.png
8391,이천제일고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6087,인천남고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-11/1_1557585486.png
10323,인천대중예술고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6394,장훈고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559035181.png
9272,전주공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102213.png
9270,정읍신태인축구센터U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9318,제주대기고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9268,제주오현고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8862,제주제일고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9269,제주중앙고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6105,제천제일고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9321,주천고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6070,중경고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-09-10/1_1568101948.png
9316,중동고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2021-05-11/64773_1620715484.png
6015,중앙대부속고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-07-21/1_1563704668.png
13867,진접FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8880,창녕고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102235.png
9282,창원기계공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
5803,천안제일고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102306.png
5802,천안축구센터 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9283,철성고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6119,청구고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606102285.png
6397,청담FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
9304,청운고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2022-04-25/81565_1650859596.5988626.png
6024,청주대성고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13870,청학FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-05-14/64773_1652529801.731329.png
4025,초지고등학교,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530364695.png
13494,춘천시체육회 U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-08-29/64773_1661759587.8586228.png
6104,충주상업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559038749.png
8874,통영고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
6395,통진고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-28/1_1559030019.png
9296,파주고려FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2021-04-26/5232_1619425910.png
4024,파주축구센터U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13874,평택JFC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
10317,평택진위FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8864,평해정보고등학교,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8858,포천시민축구단U18,https://storage.googleapis.com/bepro-server-storage/img/media/2022-07-09/64773_1657340043.9189916.png
4055,한마음축구센터 U18,https://storage.googleapis.com/bepro-server-storage/img%2Fmedia%2F2018-06-30%2F1_1530364477.png
6234,한양공업고등학교,https://storage.googleapis.com/bepro-server-storage/img/media/2019-05-27/1_1558932098.png
10237,합천스포츠클럽FCU18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
8865,홍천FC U18,https://storage.googleapis.com/bepro-server-storage/img/media/2020-11-23/5232_1606097561.png
9278,화성FC U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png
13873,화성시 U18,https://storage.googleapis.com/bepro-server-storage/img/defaults/profile-team.png`
