import {useState, useEffect, useRef} from "react";
import "./ModalSelectTeam.scss";

const ModalSelectTeam = ({teams, display, onItemSelect, onClose}) => {
  const [searchString, setSearchString] = useState("");
  const [searchedTeam, setSearchedTeams] = useState(teams);
  const searchInputRef = useRef(null);

  useEffect( () => {
    if(display)
      searchInputRef.current.focus();
  }, [display])

  const searchTeam = (searchStr) => {
    setSearchString(searchStr);
    let searchResult = teams.filter((team, index) => {
      return team.name.toLowerCase().includes(searchStr);
    });
    setSearchedTeams(searchResult);
  }

  const renderTeamItem = (team, index) => {
    return (
      <button className={`btn-item`}
              key={index}
              onClick={() => {onItemSelect(team);}}>
        <div className="emblem"
             style={{
               background: `url(${team.img}) center no-repeat`,
               backgroundSize: "contain"}}/>
        <p>{team.name}</p>
      </button>
    )
  }

  return (
    <div className={`dropdown select-team ${display ? 'show' : 'hide'}`}>
      <div className="dropdown-header">
        <button className="btn-close" onClick={() => onClose()}>닫기</button>
        <input className="allow-focus" ref={searchInputRef}
               type="text" placeholder={"팀을 검색하세요"} value={searchString}
               onChange={e => {searchTeam(e.target.value)}}/>
      </div>
      <div className="dropdown-body">

        {searchString ? (
          searchedTeam.length ? (searchedTeam.map((team, index) => renderTeamItem(team,index)))
            : (<div className="empty">검색 결과가 없습니다</div>)
        ) : teams.map( (team, index) => renderTeamItem(team,index))}
      </div>
    </div>
  )
}

export default ModalSelectTeam;
