import "./PageFooter.scss";


const PageFooter = () => {
  const companyInfo = [
    {title: "대표자", value: "강현욱"},
    {title: "이메일", value: "contact@bepro11.com"},
    {title: "사업자등록번호", value: "119-87-07119"},
    {title: "주소", value: "서울 특별시 종로구 종로1길50, B동 3층 119호"}
  ]

  return (
    <div className="page-footer">
      <div className="boundary">
        <div className="company-info">
          <img src={require("../../img/bepro-ci-black.png")} alt=""/>
          <p>(주)비프로컴퍼니</p>
          {companyInfo.map( (infoItem, index) => {
            return (
              <div className="company-info-item" key={index}>
                <span className="label">{infoItem.title}</span>
                <span className="value">{infoItem.value}</span>
              </div>
            )
          })}
        </div>

        <div className="sns-n-legal">
          <div className="sns-container">
            <a className="bepro-linkedin-fill" href="https://www.linkedin.com/company/bepro-company/" target={"_blank"}/>
            <a className="bepro-instagram-fill" href="https://www.instagram.com/bepro_korea/" target={"_blank"}/>
            <a className="bepro-youtube-fill" href="https://www.youtube.com/channel/UCZuGbj-jxkw3mtQV-u8U_Pg" target={"_blank"}/>
            <a className="bepro-facebook-round-fill" href="https://www.facebook.com/bepro11" target={"_blank"}/>
            <a className="bepro-twitter-fill" href="" target={"_blank"}/>
          </div>
          <div className="legal">
            <a href="http://terms-of-use.bepro11.com/ko-GB" target={"_blank"}>이용약관</a>
            <a href="http://privacy-policy.bepro11.com/ko-GB" target={"_blank"}>개인정보 이용방침</a>
          </div>
        </div>

      </div>
    </div>
  )
}

export default PageFooter;
